<template>
  <div class="search-screen">
    <div class="search-screen__top">
      Спрашивайте
    </div>

    <canvas class="search-screen__visualization"></canvas>

    <div class="search-screen__info">
      Задайте вопрос голосом<br/> или выберите один из популярных ниже
    </div>

    <div class="tags">
      <swiper
          :options="{
          slidesPerView: 'auto',
          slidesPerColumn: 2,
          slidesPerGroup: 3,
          touchRatio: 2
        }"
      >
        <swiper-slide
            v-for="(tag, index) in GET_FACTS"
            :key="index"
        >
          <tag
              :title="tag.title"
              @click.native="selectVariant(tag.title, tag.description)"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Tag from "@/components/Parts/Tag";
import { mapGetters } from "vuex";

export default {
  name: 'BuiltinVoiceSearch',

  components: {
    Tag,
  },

  mounted() {
    this.initRecognition();
  },

  beforeDestroy() {
    this.recognition.stop();
  },

  computed: {
    ...mapGetters(['GET_FACTS']),
  },

  data() {
    return {
      result: '',
      lang: 'ru-RU',
      recognition: null,
      recognizing: false,
    }
  },

  methods: {
    initRecognition() {
      console.log('Start initializing speech recognition');
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

      if (!SpeechRecognition) {
        throw new Error('Speech Recognition does not exist on this browser. Use Chrome or Firefox.');
      }

      console.log(SpeechRecognition, 'SpeechRecognition if available');

      let _this = this;
      this.recognition = new SpeechRecognition();
      // this.recognition.continuous = true;
      // this.recognition.interimResults = true;
      this.recognition.lang = this.lang;

      console.log(this.recognition, 'SpeechRecognition constructor');

      navigator.mediaDevices.getUserMedia({ audio: true })

          //.then(stream => this.$visualize(stream));

      this.recognition.onresult = function (event) {
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          _this.result = event.results[i][0].transcript;
          console.log(event.results[i][0].transcript, 'trans');
        }
      }

      this.recognition.onend = function(event) {
        if (_this.result !== '') {
          console.log(event, 'recognition.onend')
          _this.onResult(_this.result);
        }
      }

      this.recognition.onerror = function(event) {
        console.log(event.error, 'error');
      }

      this.recognition.start();
    },

    onResult(result) {
      this.$router.push({ name: 'SearchResults', params: { type: 'search', title: result } });
    },

    selectVariant(title, desc) {
      this.$router.push({ name: 'SearchResults', params: { type: 'texttospeech', title: title, desc: desc } });
    },
  }
}
</script>
