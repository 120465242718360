<template>
  <div class="search-screen">
    <div class="search-screen__top">
      Спрашивайте
    </div>

    <canvas class="search-screen__visualization"></canvas>

    <div class="search-screen__info">
      Задайте вопрос голосом<br/> или выберите один из популярных ниже
    </div>

    <div class="tags">
      <swiper
          :options="{
          slidesPerView: 'auto',
          slidesPerColumn: 2,
          slidesPerGroup: 3,
          touchRatio: 2
        }"
      >
        <swiper-slide
            v-for="(tag, index) in GET_FACTS"
            :key="index"
        >
          <tag
              :title="tag.title"
              @click.native="selectVariant(tag.title, tag.description)"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Tag from "@/components/Parts/Tag";
import { mapGetters } from "vuex";

export default {
  name: 'YandexVoiceSearch',

  components: {
    Tag,
  },

  mounted() {
    this.initRecognition();
  },

  computed: {
    ...mapGetters(['GET_FACTS']),
  },

  data() {
    return {
      result: '',
      audioCtx: null,
      recorder: null,
      chunks: [],
    }
  },

  methods: {
    initRecognition() {
      let _this = this;

      navigator.mediaDevices.getUserMedia({audio: true}).then(stream => {
        _this.recorder = new MediaRecorder(stream);
        _this.$visualize(stream);
        _this.detectSilence(stream, _this.onSilence, _this.onSpeak);

        _this.recorder.onstop = function() {
          let blob = new Blob(_this.chunks, {'type': 'audio/ogg; codecs=opus'});
          _this.chunks = [];
          _this.uploadSoundData(blob);
        }

        _this.recorder.ondataavailable = function(e) {
          _this.chunks.push(e.data);
        }

        _this.recorder.start();
      });
    },

    detectSilence(
        stream,
        onSoundEnd,
        onSoundStart,
        silence_delay = 3000,
        min_decibels = -31
    ) {
      const ctx = new AudioContext();
      const analyser = ctx.createAnalyser();
      const streamNode = ctx.createMediaStreamSource(stream);
      streamNode.connect(analyser);
      analyser.minDecibels = min_decibels;

      const data = new Uint8Array(analyser.frequencyBinCount); // will hold our data
      let silence_start = performance.now();
      let triggered = false; // trigger only once per silence event

      function loop(time) {
        requestAnimationFrame(loop); // we'll loop every 60th of a second to check
        analyser.getByteFrequencyData(data); // get current data
        if (data.some(v => v)) { // if there is data above the given db limit
          if(triggered){
            triggered = false;
            onSoundStart();
          }
          silence_start = time; // set it to now
        }
        if (!triggered && time - silence_start > silence_delay) {
          onSoundEnd();
          triggered = true;
        }
      }
      loop();
    },

    onSilence() {
      console.log(this.recorder.state)
      if (this.recorder.state === 'recording' && this.recorder !== null) {
        this.recorder.stop();
      }

      console.log('silence');
    },

    onSpeak() {
      if (this.recorder.state === 'inactive' && this.recorder !== null) {
        this.recorder.start();
      }

      console.log('speaking');
    },

    uploadSoundData(blob) {
      let _this = this;
      const filename = 'record-' + new Date().getTime();
      const formData = new FormData();
      formData.append('audio', blob, filename);

      // TODO: API для обработки айдиофайла
      fetch('http://localhost:80/api/recognize', {
        method: 'POST',
        body: formData
      }).then(async result => {
        _this.onResult(await result.text());
      }).catch(error => {
        console.log(error);
      })
    },

    onResult(result) {
      this.$router.push({ name: 'SearchResults', params: { type: 'search', title: result } });
    },

    selectVariant(title, desc) {
      this.$router.push({ name: 'SearchResults', params: { type: 'texttospeech', title: title, desc: desc } });
    },
  }
}
</script>
