<template>
  <div>
    <BuiltinVoiceSearch v-if="voiceRecognitionProvider === 'built-in'" />
    <YandexVoiceSearch v-if="voiceRecognitionProvider === 'yandex'" />
  </div>
</template>

<script>
import BuiltinVoiceSearch from "../components/BuiltinVoiceSearch";
import YandexVoiceSearch from "../components/YandexVoiceSearch";

export default {
  name: 'Search',

  components: {
    YandexVoiceSearch,
    BuiltinVoiceSearch,
  },

  mounted() {
    console.log(this.voiceRecognitionProvider)
  },

  data() {
    return {
      voiceRecognitionProvider: process.env.VUE_APP_VOICE_SEARCH_PROVIDER, // 'built-in' or 'yandex' in .env
    }
  },
}
</script>

<style lang="scss">
.search-screen {
  height: calc(100vh - 768px);
  width: 100%;
  overflow-y: auto;
  background: linear-gradient(220.34deg, #004EA8 0%, #981E97 100%);
  padding-top: 196px;

  &__top {
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.36px;
    text-align: center;

    color: rgba(255, 255, 255, 0.95);
  }

  &__info {
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;
    text-align: center;

    color: #FFFFFF;
  }

  &__visualization {
    margin: 0 auto;
    display: block;
  }

  .tags {
    padding: 0 32px;
    margin-top: 64px;

    .swiper-slide {
      padding: 0 0 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .tag {
    background: rgba(255, 255, 255, 0.2);
  }
}
</style>
