<template>
  <div class="rir-spinner">
    <div :size="this.size" :style="styleRing"></div>
    <div :size="this.size" :style="styleRing"></div>
    <div :size="this.size" :style="styleRing"></div>
    <div :size="this.size" :style="styleRing"></div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    size: {
      type: [Number, String],
      default: 16,
    },
  },
  computed: {
    styleRing() {
      let size = this.size;
      switch (this.size) {
        case 'very-small':
          size = 8;
          break;
        case 'small':
          size = 16;
          break;
        case 'medium':
          size = 24;
          break;
        case 'large':
          size = 32;
          break;
        case 'very-large':
          size = 64;
          break;
      }
      return {
        border: `16px solid ${this.color}`,
        borderColor: `${this.color} transparent transparent transparent`,
        width: `${size}px`,
        height: `${size}px`,
      };
    },
  },
};
</script>

<style lang="scss">
.rir-spinner {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 8px;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

