<template>
  <div class="results">
    <div class="result-item" v-for="item in results" :key="item.id" @click="goToObject(item)">
      <div class="result-item__image">
        <img v-if="item.images.length > 0" :src="item.images[0]" :alt="item.title" />
      </div>

      <div class="result-item__info">
        <div class="result-item__top">
          <div class="result-item__type">
            {{ item.type_title }}
          </div>

          <div class="result-item__title" v-html="item.title" v-line-clamp:46="1"></div>
        </div>

        <div class="result-item__address" v-html="item.address" v-if="item.address" v-line-clamp:32="1"></div>

        <div class="result-item__desc" v-html="item.description" v-else v-line-clamp:64="2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchResults',

  props: {
    results: {
      type: [Array, Object],
      default: () => [],
    },
    facts: {
      type: [Array, Object],
      default: () => [],
    },
  },

  mounted() {
    if (this.facts.length > 0) {
      this.utterance = new SpeechSynthesisUtterance(this.facts[0].description);
      this.utterance.lang = 'ru-RU';
      speechSynthesis.speak(this.utterance);
    }
  },

  beforeDestroy() {
    speechSynthesis.cancel();
  },

  data() {
    return {
      utterance: null,
    }
  },

  methods: {
    async goToObject(object) {
      if (object.entity === 'places' || object.entity === 'trips' || object.entity === 'excursions') {
        await this.$store.dispatch("setSelectedObject", object);

        this.$router.push({ path: `/object/${object.id}` });
      }

      if (object.entity === 'events') {
        this.$router.push({ path: `/event/${object.id}` });
      }

      if (object.entity === 'facts') {
        this.$router.push({ path: `/fact/${object.id}` });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.results {
  height: calc(100vh - 1138px);
  overflow-y: auto;
  padding: 0 32px 60px;
}

.result-item {
  display: flex;
  align-items: center;
  height: 214px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;

  margin-bottom: 40px;


  &__image {
    flex-shrink: 0;
    width: 379px;
    height: 214px;
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.3);

    img {
      width: 379px;
      height: 214px;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    height: 100%;

    padding: 16px;
  }

  &__type {
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.97);
    opacity: 0.7;

    margin-bottom: 8px;
  }

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 117.3%;
    letter-spacing: -0.656px;
    color: #FFFFFF;

    /deep/ span {
      color: #F3BC46;
    }
  }

  &__address,
  &__desc {
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.97);

    margin-top: auto;

    /deep/ span {
      color: #F3BC46;
    }
  }
}
</style>
