<template>
  <div>
    <div class="text-to-speech">
      {{ desc }}
    </div>

    <div class="button-search" @click="goToSearch">
      <div class="icon">
        <search-icon />
      </div>
      <div class="text">
        Задать другой вопрос
      </div>
    </div>
  </div>
</template>

<script>
import SearchIcon from "@/components/Icons/Search";

export default {
  name: 'TextToSpeech',

  components: {
    SearchIcon,
  },

  created() {
    this.utterance = new SpeechSynthesisUtterance(this.desc);
    this.utterance.lang = 'ru-RU';
    speechSynthesis.speak(this.utterance);
  },

  beforeDestroy() {
    speechSynthesis.cancel();
  },

  props: {
    title: {
      type: String,
      default: () => '',
    },
    desc: {
      type: String,
      default: () => '',
    }
  },

  data() {
    return {
      utterance: null,
    }
  },

  methods: {
    goToSearch() {
      this.$router.push({ path: '/search' });
    },
  }
}
</script>

<style lang="scss" scoped>
.text-to-speech {
  font-weight: normal;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: 0.45px;
  color: rgba(255, 255, 255, 0.75);

  padding: 0 32px;
}

.button-search {
  height: 112px;
  width: 617px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 55px;
  transition-delay: 0s;
  overflow: hidden;
  background: #1A8BFF;

  margin: 80px auto 0;

  .icon {
    margin-right: 12px;
    flex-shrink: 0;
  }

  .text {
    font-weight: normal;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: -0.5px;

    color: rgba(255, 255, 255, 0.97);
    overflow: hidden;
  }
}
</style>
