<template>
  <div class="no-results">
    <div class="no-results__icon">
      <box-icon />
    </div>

    <div class="no-results__text">
      По вашему вопросу ничего не нашлось
    </div>

    <div class="button-search" @click="goToSearch">
      <div class="icon">
        <search-icon />
      </div>
      <div class="text">
        Задать другой вопрос
      </div>
    </div>
  </div>
</template>

<script>
import BoxIcon from '@/components/Icons/Box';
import SearchIcon from "@/components/Icons/Search";

export default {
  name: 'NoSearchResults',

  components: {
    BoxIcon,
    SearchIcon,
  },

  methods: {
    goToSearch() {
      this.$router.push({ path: '/search' });
    },
  },
}
</script>

<style lang="scss" scoped>
.no-results {
  width: 100%;

  margin-top: 150px;

  &__text {
    font-weight: normal;
    font-size: 32px;
    line-height: 117.3%;
    text-align: center;
    letter-spacing: -0.656px;
    color: rgba(255, 255, 255, 0.97);
    margin-top: 40px;
  }

  &__icon {
    display: flex;
    justify-content: center;
  }

  .button-search {
    height: 112px;
    width: 617px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 55px;
    transition-delay: 0s;
    overflow: hidden;
    background: #1A8BFF;

    margin: 80px auto 0;

    .icon {
      margin-right: 12px;
      flex-shrink: 0;
    }

    .text {
      font-weight: normal;
      font-size: 44px;
      line-height: 48px;
      letter-spacing: -0.5px;

      color: rgba(255, 255, 255, 0.97);
      overflow: hidden;
    }
  }
}
</style>
