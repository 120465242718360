<template>
  <div class="search-results-screen">
    <div class="search-results-screen__voice-result">
      <AudioVisualization/>
      <span v-line-clamp:60="3" class="search-results-screen__text">
        {{ capitalizeFirstLetter($route.params.title) }}
      </span>
    </div>

    <div class="spinner" v-if="progress">
      <Spinner :size="200"/>

      <div class="spinner__text">
        Ищем ответ на ваш вопрос.<br/>Это может занять несколько секунд.
      </div>
    </div>

    <TextToSpeech v-if="$route.params.type === 'texttospeech' && !progress" :title="$route.params.title"
                  :desc="$route.params.desc"/>

    <div v-if="$route.params.type === 'search' && !progress">
      <div v-if="results.length > 0">
        <div v-if="facts.length > 0" class="search-results-screen__info">
          <span>Прослушайте интересный факт на эту тему!</span><br/>
          А ещё вот, что удалось найти:
        </div>

        <div v-else class="search-results-screen__info">
          Вот что удалось найти:
        </div>

        <SearchResults :results="results" :facts="facts"/>
      </div>

      <NoSearchResults v-else/>
    </div>
  </div>
</template>

<script>
import NoSearchResults from '@/components/NoSearchResults';
import SearchResults from '@/components/SearchResults';
import Spinner from '@/components/Icons/Spinner';
import AudioVisualization from '@/components/Icons/AudioVisualization';
import TextToSpeech from '@/components/TextToSpeech';

export default {
  name: 'SearchResultsScreen',

  components: {
    NoSearchResults,
    SearchResults,
    TextToSpeech,
    Spinner,
    AudioVisualization,
  },

  computed: {},

  data() {
    return {
      results: [],
      facts: [],
      progress: false,
    }
  },

  mounted() {
    if (this.$route.params.type === 'search') {
      this.getSearchResults(this.$route.params.title);
    }
  },

  methods: {
    getSearchResults(query) {
      this.progress = true;

      api.getSearchFactsResults(query).then(resolve => {
        this.facts = resolve.data;
      }).then(() => {
        api.getSearchResults(query).then(resolve => {
          console.log(resolve);
          this.results = resolve.data;
          this.progress = false;
        });
      });
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
}
</script>

<style lang="scss" scoped>
.search-results-screen {
  width: 100%;
  //padding-top: 140px;

  &__voice-result {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 273px;

    svg {
      position: absolute;
      top: 0;

      opacity: 0.3;
    }
  }

  &__text {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.36px;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);

    padding: 0 32px;
  }

  &__info {
    font-weight: normal;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: rgba(255, 255, 255, 0.97);

    margin-bottom: 64px;
    //margin-top: 140px;

    span {
      color: #FFA800;
    }
  }
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;

  //margin-top: 140px;

  &__text {
    font-weight: normal;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;

    margin-top: 58px;
  }
}
</style>
